@import "minima";
@import "syntax_highlight.css";

div.post-author {
  border-top: 1px solid rgba(230, 230, 230, 1);
  margin-top: 25px;
  margin-bottom: 25px;
  padding-top: 25px;

  div .post-author-avatar {
    position: absolute;
  }

  img.post-author-avatar {
    margin-right: 15px;
    margin-top: 7px;
    margin-bottom:15px;
    border-radius: 50%;
    width: 100px;
  }

  .post-author-content {
    margin-left: 115px;
  }

}

.post-tag {
  display: inline-block;
  background: rgba(106,159,181,0.15);
  padding: 0 .5rem;
  margin-right: .5rem;
  border-radius: 4px;
  color: #6a9fb5;
  font-family: "PT Sans",Helvetica,Arial,sans-serif;
  font-size: 90%;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.post-tag::before {
  display: none; /* use "display: none;" if using FontAwesome SVG+JS framework; "display: inline-block;" if using CSS */
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-weight: 900; /* Required for FontAwesome > 5 */
  font-family: "Font Awesome 5 Free"; /* FontAwesome for 4.x.x and "Font Awesome 5 Free" for FA > 5 */
  content: "\f02b";
  color: steelblue;
  padding-right: 0.5em;
}

a.post-tag:visited {
  @extend a.post-tag;
}
